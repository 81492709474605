import React from 'react'
import { arrayOf, any, string } from 'prop-types'
import { navigate } from 'gatsby'
import sortBy from 'lodash/sortBy'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { productAvailability } from '@helpers/product'
import { productUrl } from '@helpers/route'
import ProductVariation from './product-variation-refactor'
import ProductSelectVariation from './product-select-variation'

const ProductVariations = ({ variations, heading, productSku }) => {
  const availableVariations = variations
    ? variations.filter(v => productAvailability(v) || !v.catalog_availability)
    : []

  if (!availableVariations) return null
  const colors = availableVariations[availableVariations.findIndex(v => v.sku === productSku)]?.variation_value || false
  if (!colors) return null

  if (heading === 'Size') {
    return (
      <ProductSelectVariation
        variations={availableVariations}
        heading="Size"
        handleChange={value =>
          navigate(productUrl(availableVariations.filter(i => i.variation_value === value)[0].route))
        }
      />
    )
  }
  return (
    <Grid container direction="column" justify="flex-start">
      <Box display="flex">
        <span>{`${heading}: `}</span>
        <Box fontWeight={700} ml="15px">
          {colors}
        </Box>
      </Box>
      <Grid container justify="flex-start">
        {sortBy(availableVariations, 'variation_value').map((v, i) => (
          <ProductVariation
            key={v.sku}
            image={v.image}
            variation_value={v.variation_value}
            index={i}
            active={productSku === v.sku}
            route={v.route}
            sku={v.sku}
            variation={v}
          />
        ))}
      </Grid>
    </Grid>
  )
}

ProductVariations.propTypes = {
  variations: arrayOf(any),
  heading: string,
  productSku: string,
}

export default ProductVariations
