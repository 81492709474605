import React from 'react'
import classNames from 'classnames'
import { objectOf, bool, string, any, number, func, array } from 'prop-types'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { setOrderInfo } from '@helpers/checkout/global'
import { connect } from 'react-redux'
import { getRegionZone } from '@helpers/geo-location'
import ProductLocation from './product-location'
import AddToCartModal from './add-to-cart-modal'
import ApplePayButton from '../../checkout/checkout-parts/payment-section/apple-pay'
import '@comp-sass/product/product-parts/add-to-cart.sass'
import StoreCartRemoveModal from '../../cart/store-cart/store-cart-remove-modal'

class AddToCart extends React.Component {
  state = {
    modalOpen: false,
    stockMessage: '',
  }

  componentDidUpdate(prevProps) {
    const { stockMessage } = this.props
    if (stockMessage && prevProps.stockMessage !== stockMessage) {
      return () =>
        this.setState({
          stockMessage,
        })
    }
    return null
  }

  onAddToCart = event => {
    event.preventDefault()
    const { modalOpen } = this.state
    const { product, componentPage } = this.props
    addToDataLayer('click', componentPage, 'add to cart', product.sku)
    if (!modalOpen) {
      this.setState({
        modalOpen: true,
      })
    }
  }

  closeModal = () => {
    const { modalOpen } = this.state
    const { removeFocus } = this.props
    if (modalOpen) {
      if (removeFocus) {
        removeFocus(false)
      }
      this.setState({
        modalOpen: false,
      })
    }
  }

  isDisabled = sku => {
    const { product, state_exclusions } = this.props
    const unavailableSkus = ['28070693', '28065692']
    const unavailableDueToStateExclusion =
      product.collection &&
      typeof product.collection === 'string' &&
      state_exclusions.includes(product.collection.toLowerCase())
    return unavailableSkus.includes(sku) || unavailableDueToStateExclusion
  }

  render() {
    const { modalOpen, stockMessage: stockMessageState } = this.state
    const {
      pageName = '',
      product,
      availability,
      price,
      focused,
      stockMessage,
      index,
      source,
      addons,
      requiredSelected,
      activeAddons,
      buttonText,
      promoDescription,
      promoItems,
      promoQualifierQuantity,
      promoTargetQuantity,
      promoTargetPrice,
      promoStrikethroughPrice,
      warrantyEnabled,
      hideShipping,
      showLocation,
      componentPage,
      applePayVertical,
    } = this.props
    const regionInPR = getRegionZone().region === 'PR'

    return (
      <>
        {/* <StoreCartRemoveModal onRemove={() => alert('remove')} onCancel={() => alert('cancel')}>
          If you remove an item from your <br />
          store cart, it cannot be added back. <br />
          Would you like to remove the item?
        </StoreCartRemoveModal> */}
        <div
          className={classNames('buy-now-popup grid-x', {
            focused,
            offerButton: buttonText === 'add offer to cart',
          })}
        >
          {!hideShipping && (
            <div className="instock-instore cell small-12">
              <ProductLocation product={product} />
            </div>
          )}
          <div className="cell small-12 grid-x grid-padding-x align-center">
            {showLocation && (
              <div className="instock-instore cell small-4">
                <ProductLocation product={product} />
              </div>
            )}
            <div className={classNames(`cell small-12 ${applePayVertical ? 'grid-x' : ''}`)}>
              {(availability &&
                (!stockMessage ||
                  stockMessage === '' ||
                  (!stockMessage.includes('Out of Stock ') &&
                    !stockMessage.includes('Not available in your region ') &&
                    !stockMessage.includes('Discontinued ') &&
                    !stockMessage.includes('Available Soon ')))) ||
              source === 'search' ? (
                <>
                  {!regionInPR && componentPage === 'pdp' && !this.isDisabled(product.sku) && (
                    <ApplePayButton
                      type="Buy"
                      isExpress
                      isPDP
                      setOrderInfo={() => setOrderInfo('Apple Pay', 'selectedPaymentType')}
                      product={product}
                      price={price}
                      activeAddons={activeAddons}
                      warrantyEnabled={warrantyEnabled}
                      componentPage={componentPage}
                    />
                  )}
                  <button
                    data-testid="addToCartPlpButton"
                    className={classNames('buy-now', {
                      unavailable: this.isDisabled(product.sku),
                      focused,
                    })}
                    aria-label={`Add To Cart for ${product.title ? product.title : ''}`}
                    onClick={this.onAddToCart}
                    type="button"
                    disabled={this.isDisabled(product.sku)}
                  >
                    {buttonText || 'add to cart'}
                  </button>
                </>
              ) : (
                <button
                  className={classNames('buy-now unavailable', {
                    focused,
                  })}
                  aria-label={`Unavailable ${product.title ? product.title : ''}`}
                  type="button"
                  disabled
                >
                  not available
                </button>
              )}
            </div>
          </div>
        </div>
        {modalOpen && (
          <AddToCartModal
            pageName={pageName}
            modalOpen={componentPage !== 'cart' && modalOpen}
            product={product}
            price={price}
            closeModal={this.closeModal}
            stockMessage={stockMessageState || stockMessage}
            addons={addons}
            requiredSelected={requiredSelected}
            activeAddons={activeAddons}
            index={index}
            promoDescription={promoDescription}
            promoItems={promoItems}
            promoQualifierQuantity={promoQualifierQuantity}
            promoTargetQuantity={promoTargetQuantity}
            promoTargetPrice={promoTargetPrice}
            promoStrikethroughPrice={promoStrikethroughPrice}
            warrantyEnabled={warrantyEnabled}
            source={source}
          />
        )}
      </>
    )
  }
}

AddToCart.propTypes = {
  pageName: string,
  product: objectOf(any),
  availability: bool,
  price: number,
  focused: bool,
  stockMessage: string,
  index: number,
  source: string,
  addons: array,
  requiredSelected: bool,
  activeAddons: array,
  buttonText: string,
  promoDescription: string,
  promoItems: array,
  promoQualifierQuantity: number,
  promoTargetQuantity: number,
  promoTargetPrice: number,
  promoStrikethroughPrice: number,
  warrantyEnabled: bool,
  removeFocus: func,
  componentPage: string,
  hideShipping: bool,
  showLocation: bool,
  applePayVertical: bool,
  state_exclusions: array,
}

const mapStateToProps = state => ({
  state_exclusions: state.location.state_exclusions,
})

export default connect(mapStateToProps)(AddToCart)
