import React, { useState } from 'react'
import { string, bool } from 'prop-types'
import { addToDataLayer } from '@helpers/google-tag-manager'
import Button from '@material-ui/core/Button'
import SeeInStoresModal from './see-in-stores-modal'
import '../../../assets/css/components/product/product-parts/see-in-stores.sass'

function SeeInStores({ componentPage, customButtonText, lia, primary_image, sku, title, zip }) {
  const [modalOpen, setModalOpen] = useState(false)

  const onSeeInStore = () => {
    setModalOpen(true)
    addToDataLayer('click', componentPage, 'see in stores', sku)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        data-testid="seeInStoreButton"
        onClick={() => onSeeInStore()}
        color="primary"
        style={{ width: '100%' }}
      >
        See In Store
      </Button>
      {modalOpen && (
        <SeeInStoresModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          zip={zip}
          lia={lia}
          sku={sku}
          title={title}
          primary_image={primary_image}
        />
      )}
    </>
  )
}

SeeInStores.propTypes = {
  componentPage: string,
  customButtonText: string,
  lia: bool,
  primary_image: string,
  title: string,
  sku: string,
  zip: string,
}

export default SeeInStores
