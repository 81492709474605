import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fonts, colors } from '@constants/styles'
import { PrimaryButton, SecondaryButton } from '@shared/button-types'

import CloseModal from '../../../shared/modals/close-modal'
import '@comp-sass/product/product-parts/add-to-cart-modal.sass'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const Header = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.lightGrey};
  color: ${colors.darkerGrey};
  font-size: ${fonts.txtMediumUp};
`

const Content = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #333333;
`
const Body = styled.div`
  width: 100%;
  padding: 20px;
`

const Actions = styled.div`
  display: flex;
  margin-top: 34px;
  &&& > button {
    width: 100%;
    margin: 0;
    :nth-child(2) {
      margin-left: 10px;
      @media only screen and (max-width: 639px) {
        min-width: auto;
      }
    }
  }
`

const ModalContent = styled.div`
  &&& {
    min-width: 350px;
    height: auto;
    width: fit-content;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, 0.6);

    .card {
      padding: 0;
      border-radius: 5px;
    }
    @media only screen and (max-width: 639px) {
      max-width: 90vw;
      min-width: 80vw;

      > div > button {
        top: -1em;
        right: -0.5em;
        width: 3em;
        height: 3em;
      }
    }
  }
`

const StoreCartRemoveModal = ({ onCancel, onRemove, children, onModalOpen }) => (
  <>
    <Modal
      isOpen={onModalOpen}
      onRequestClose={onCancel}
      contentLabel="Remove item from store cart"
      className="add-to-cart-modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <ModalContent className="modal-content">
        <div className="card grid-x grid-margin-x">
          <CloseModal closeModal={onCancel} />
          <Header>Remove Item?</Header>
          <Body>
            <Content>{children}</Content>
            <Actions>
              {onCancel && <SecondaryButton onClick={onCancel}>CANCEL</SecondaryButton>}
              {onRemove && <PrimaryButton onClick={onRemove}>REMOVE</PrimaryButton>}
            </Actions>
          </Body>
        </div>
      </ModalContent>
    </Modal>
  </>
)

StoreCartRemoveModal.propTypes = {
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onModalOpen: PropTypes.func,
  children: PropTypes.any,
}

export default StoreCartRemoveModal
