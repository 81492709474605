/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { object, objectOf, any, arrayOf, number, bool, node, string, func } from 'prop-types'
import classNames from 'classnames'
import SlickSlider from '@rtgdev/react-slick'
import '@comp-sass/shared/slider/slick.scss'
import '@comp-sass/shared/slider/slick-theme.scss'
import '@comp-sass/shared/slider.sass'
import { getWindowSizeSettings, removeUnavailableProducts } from '@helpers/slider'
import useWindowResize from '@helpers/useWindowResize'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
import SliderArrow from './slider-arrow'
import RTGLink from './link'

const ExtraMargin = styled.div`
  margin: 3.5rem 0;

  @media only screen and (max-width: ${breakPoints.small}) {
    margin: 2.5rem 0;
  }
`

const SimpleSlider = ({
  slideId,
  data,
  maxSlides,
  minSlidesMobile,
  children,
  dots,
  focusOnSelect,
  lazyLoad,
  cssHideClass,
  testid,
  type,
  label,
  instructions,
  slideOnClick,
  slideOnKeyDown,
  initialSlide: initialSlideProps,
  noExtraMargin,
}) => {
  const sliderEle = useRef(null)
  const initialWindow = typeof window !== 'undefined' ? window.innerWidth : null
  const [windowWidth, setWindowWidth] = useState(initialWindow)
  const [filteredProductSlides, setFilteredProductSlides] = useState([])
  const slider = data || null

  useEffect(() => {
    let isFetching = true
    let filteredProducts
    const myFunc = async () => {
      try {
        filteredProducts = await removeUnavailableProducts(slider, setFilteredProductSlides)
        if (isFetching) {
          setFilteredProductSlides(filteredProducts)
        }
      } catch (error) {
        setFilteredProductSlides(slider?.slides)
      }
    }
    myFunc()

    // eslint-disable-next-line no-return-assign
    return () => (isFetching = false)
  }, [slider])

  const checkWindowWidth = () => {
    if (window && window.innerWidth && window.innerWidth !== windowWidth) setWindowWidth(window.innerWidth)
  }

  useWindowResize(checkWindowWidth, initialWindow)

  if (slider && slider.title)
    slider.slides = slider.slides.map(slide => {
      slide.sliderTitle = slider.title
      return slide
    })

  const minSlides = minSlidesMobile
  const contentComponents = children

  const infinite = false
  const initialSlide = initialSlideProps || 0
  const nextArrow = slider && (
    <SliderArrow
      text="Next"
      caption={slider.caption}
      describedby={slider.describedby}
      label={slider.label}
      classNames="slick-next"
      instance={sliderEle}
      heading={slider.heading}
    />
  )
  const prevArrow = slider && (
    <SliderArrow
      text="Previous"
      caption={slider.caption}
      describedby={slider.describedby}
      label={slider.label}
      classNames="slick-prev"
      instance={sliderEle}
      heading={slider.heading}
    />
  )
  const settings =
    slider &&
    getWindowSizeSettings({
      maxSlides,
      contentComponents,
      minSlides,
      nextArrow,
      prevArrow,
      lazyLoad,
      initialSlide,
      infinite,
      windowWidth,
      focusOnSelect,
      dots,
      sliderEle,
      sliderTitle: slider.title || slider.heading || '',
      cssHideClass,
      testid,
      type,
      label,
      instructions,
      slideOnClick,
      slideOnKeyDown,
    })

  if (contentComponents) {
    if (slider.viewAllLink) {
      if (slider.viewAllLink.displayText && !slider.viewAllLink.displayText.includes('>')) {
        slider.viewAllLink.displayText = `${slider.viewAllLink.displayText} >`
      } else if (slider.viewAllLink.text && !slider.viewAllLink.text.includes('>')) {
        slider.viewAllLink.text = `${slider.viewAllLink.text} >`
      }
    }
  }

  const content = (
    <>
      {contentComponents && (
        <section
          ref={sliderEle}
          id={slideId}
          className={classNames('slider', {
            small: slider.sliderSize && slider.sliderSize === 'Small',
          })}
        >
          <div className="slider-head">
            <div id={slider.ariaId} className="slider-heading">
              {slider.heading && (
                <div className="slider-heading" role="heading" aria-level={slider.headingLevel || 2}>
                  <span className="hide508">{slider.ariaNote} </span>
                  {slider.heading}
                </div>
              )}
            </div>
            <div className="slider-link">{slider.viewAllLink && <RTGLink data={slider.viewAllLink} />}</div>
          </div>
          <SlickSlider {...settings}>{contentComponents}</SlickSlider>
        </section>
      )}
    </>
  )

  if (noExtraMargin || slider?.sliderSize === 'Small') return content
  return <ExtraMargin>{content}</ExtraMargin>
}

SimpleSlider.propTypes = {
  slideId: string,
  data: objectOf(any),
  disabledCoupons: arrayOf(any),
  maxSlides: number,
  minSlidesMobile: number,
  page: object,
  children: node,
  dots: bool,
  lazyLoad: bool,
  focusOnSelect: bool,
  initialSlide: number,
  cssHideClass: string,
  testid: string,
  type: string,
  label: string,
  instructions: string,
  slideOnClick: func,
  slideOnKeyDown: func,
  noExtraMargin: bool,
}

export default SimpleSlider

export const sliderFragment = graphql`
  fragment Slider on ContentfulSlider {
    title
    heading
    sliderSize
    startDate
    endDate
    viewAllLink {
      ...Link
    }
    slides {
      __typename
      ...ProductTile
      ...CategoryTile
      ...CollectionTile
      ...Coupon
      ...BannerButton
      ...Markdown
      ...Button
    }
    contentful_id
  }
`
