import React from 'react'
import PropTypes from 'prop-types'

const SliderArrow = ({ text, caption, describedby, label, classNames, heading, instance, onClick }) => (
  <button
    type="button"
    data-role="none"
    className={classNames}
    gtm-category="slider"
    gtm-action="slider scroll"
    gtm-label={heading}
    onClick={onClick}
    aria-describedby={describedby || null}
    aria-label={text && label ? `${text} for ${label}` : null}
  >
    <svg
      role="presentation"
      aria-hidden="true"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      preserveAspectRatio="xMinYMin"
    >
      {text.includes('Next') ? (
        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
      ) : (
        <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
      )}
    </svg>
    <span className="hide508">{`${text} for ${caption || heading || ''}`}</span>
  </button>
)

SliderArrow.propTypes = {
  text: PropTypes.string,
  caption: PropTypes.string,
  heading: PropTypes.string,
  classNames: PropTypes.string,
  describedby: PropTypes.string,
  onClick: PropTypes.func,
  instance: PropTypes.object,
  label: PropTypes.any,
}

export default SliderArrow
