import React from 'react'
import RTGLink from '@shared/link'

const ProductSleepTrialLink = () => (
  <>
    <div className="sleep-trial-wrapper">
      <div className="grid-y">
        <RTGLink
          className="info"
          data={{
            target: '_blank',
            url: 'https://www.casper.com/trial',
            category: 'product sleep trial',
            action: 'link to casper click',
            label: 'Sleep Trial Details',
          }}
        >
          100 Night Sleep Trial <span className="info-link">Details</span>
        </RTGLink>
      </div>
    </div>
  </>
)

export default ProductSleepTrialLink
