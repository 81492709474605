import React from 'react'
import { array, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import theme from '../../../@rtg2022/material-ui-theme'

const useStyles = makeStyles({
  formControl: {
    minWidth: '100%',
    height: 50,
    marginTop: 20,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  selectDropdown: {
    maxWidth: 355,
    width: '100%',
  },
})
const SelectVariation = ({ variations, heading, handleChange }) => {
  const classes = useStyles()
  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink htmlFor="outlined-size-native-simple">
        {heading}
      </InputLabel>
      <Select
        native
        label={heading}
        className={classes.selectDropdown}
        onChange={event => handleChange(event.target.value)}
        inputProps={{
          heading,
          id: 'outlined-size-native-simple',
        }}
      >
        {variations.map(v => (
          <option key={v.sku} value={v.variation_value}>
            {v.variation_value}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}
SelectVariation.propTypes = {
  variations: array,
  heading: string,
  handleChange: func,
}

export default SelectVariation
