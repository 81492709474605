import { productAvailability } from '@helpers/product'
import { fetchProductBySku } from '@services/product'

export const getWindowSizeSettings = ({
  maxSlides,
  contentComponents,
  minSlides,
  nextArrow,
  prevArrow,
  lazyLoad,
  initialSlide,
  infinite,
  windowWidth,
  focusOnSelect,
  dots,
  sliderTitle,
  cssHideClass,
  testid,
  type,
  label,
  instructions,
  slideOnClick,
  slideOnKeyDown,
}) => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: false,
    infinite,
    lazyLoad,
    speed: 500,
    initialSlide,
    dots,
    focusOnSelect,
    nextArrow,
    prevArrow,
    sliderTitle,
    cssHideClass,
    testid,
    type,
    label,
    instructions,
    slideOnClick,
    slideOnKeyDown,
  }
  let compare = null

  if (windowWidth && windowWidth > 0) {
    if (windowWidth <= 680) {
      settings.slidesToShow = minSlides || 1
      settings.slidesToScroll = minSlides ? minSlides - 1 : 1
      settings.dots = dots
      settings.arrows = !dots
    } else if (windowWidth > 680 && windowWidth < 1224) {
      settings.slidesToShow = minSlides || 2
      settings.slidesToScroll = minSlides ? minSlides - 1 : 1
    } else if (windowWidth >= 1500) {
      compare = maxSlides ? Math.min(contentComponents.length, maxSlides) : Math.min(contentComponents.length, 4)
      settings.slidesToShow = compare
      settings.slidesToScroll = compare > 1 ? compare - 1 : compare
    } else {
      compare = maxSlides ? Math.min(contentComponents.length, maxSlides) : Math.min(contentComponents.length, 3)
      settings.slidesToShow = compare
      settings.slidesToScroll = compare > 1 ? compare - 1 : compare
    }
  }
  return settings
}

export const removeUnavailableProducts = async slider => {
  let filteredSlides = []
  if (slider?.slides?.[0]?.__typename === 'ContentfulProductTile') {
    filteredSlides = slider.slides.map(async slide => {
      if (!slide.sku) {
        return null
      }
      const product = await fetchProductBySku(slide.sku)

      if (product && productAvailability(product)) {
        return slide
      }
      return null
    })
  }
  return Promise.all(filteredSlides).then(slides => slides.filter(slide => slide !== null))
}
