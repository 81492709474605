// dependencies
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import { object, any, bool, string, arrayOf, number, func } from 'prop-types'
import { isSafari } from 'react-device-detect'
import { makeStyles } from '@material-ui/core'
import { PLP } from '@constants/page-names'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { useUpdateFavorites, useIsFavorited } from '@hooks/auth'

// Helpers
import { scrollToPLP } from '@helpers/product'
import { analyticsProduct, addToDataLayer_nextgen } from '@helpers/google-tag-manager'
// Components
import SaleFlag from '@components/shared/sale-flag'
import { getRegionZone } from '@helpers/geo-location'
import { FAVORITE_BUTTON } from '../../../@rtg2022'
import AlternateImages from '../product-parts/product-alternate-images'
import AddToCart from '../product-parts/product-add-to-cart'
import ProductFinancing from '../product-parts/product-financing'
import ProductLocation from '../product-parts/product-location'
import ProductPrice from '../product-parts/product-price'
import Variations from '../product-parts/product-variations'
// hooks
import { useProduct } from '../hooks'

const regionInPR = getRegionZone().region === 'PR'

const useStyles = makeStyles(theme => ({
  variantPlaceholder: {
    height: '54px',
  },
  hidden: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
  favoriteButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
}))

const ProductInfoGrid = ({
  setProduct,
  orderedProductVariations,
  product,
  strikeThrough,
  strikeThroughPrice,
  availability,
  fullWidth,
  currentImage,
  setImage,
  index,
  source,
  sliderTitle,
  isMobile,
  addons,
  displayQuantity,
  showFinance,
  financeAmount,
  last,
}) => {
  const { price, sale, route, isMattress, isItem, availabilityDate, shouldDisplayAddToCart } = useProduct(product)
  const [focused, setFocused] = useState(false)
  const classes = useStyles()
  const handleFavoriteClick = useUpdateFavorites(product)
  const isFavorited = useIsFavorited(product.sku)

  const removeFocus = (event = null) => {
    const code = event ? event.keyCode || event.which : null
    if (
      !code ||
      (code === 9 && event.target.className === 'buy-now focused') ||
      !event ||
      (event.shiftKey &&
        event.keyCode === 9 &&
        typeof event.target.className === 'string' &&
        event.target.className.includes('product-image-link'))
    ) {
      setFocused(false)
    }
  }

  const handleVariationClick = useCallback(
    variation => {
      setProduct(variation.sku)
    },
    [setProduct],
  )

  const variationType = useMemo(() => {
    if (orderedProductVariations?.color && orderedProductVariations.color.length > 0) {
      return 'color'
    }
    if (orderedProductVariations?.finish && orderedProductVariations.finish.length > 0) {
      return 'finish'
    }
    return null
  }, [orderedProductVariations])

  return (
    <div
      onKeyDown={e => removeFocus(e)}
      className={classNames('product-tile plp-grid card', {
        'pop-out': !fullWidth,
        wide: fullWidth,
        focused,
      })}
      role="presentation"
    >
      {product && (
        <>
          {!regionInPR && (
            <>
              <div className={classes.favoriteButton}>
                <FAVORITE_BUTTON checked={isFavorited} handleClick={handleFavoriteClick} />
              </div>
            </>
          )}
          <SaleFlag product={product} availabilityDate={availabilityDate} />
          <div className="item product grid-margin-x">
            <span
              id={`cell${product.sku}`}
              className="hide508"
              aria-describedby={product && product.title && price ? `$${price} - ${product.title}` : `${product.title}`}
            >
              {`${product.brand ? product.brand : ''} ${product.category}`}
            </span>
            {displayQuantity > 1 && <div className="item-count">{`qty: ${displayQuantity}`}</div>}
            <StrapiLink
              className="product-image-link"
              data={{
                InternalUrl: route,
                id: `product-tile-link:${product.sku}`,
                Title: product.title,
              }}
              trackingData={{
                category: 'product tile',
                action: 'click',
                event: 'ee_click',
                label: product.sku,
                value: index + 1,
                ecommerce: {
                  click: {
                    position: index,
                    products: [analyticsProduct(product, 1, index)],
                    list: sliderTitle ? `${sliderTitle}` : `${source && source.replace(/[-_]/g, ' ')}`,
                  },
                },
              }}
              aria-hidden="true"
              tabIndex="-1"
            >
              <span
                className={classNames('product-image', {
                  loading: !(product.primary_image || product.grid_image),
                  gradient: isMattress,
                  isItem,
                })}
                data-src={`${`${product.primary_image_item}|${product.grid_image_room}`}`}
              >
                {((typeof window !== 'undefined' && product.primary_image_item) ||
                  product.grid_image_room ||
                  product.grid_image) && (
                  <img
                    src={`${product.primary_image}&h=385${isSafari ? '&fm=png' : ''}`}
                    className="small-image"
                    alt={`${product.title}`}
                    role="presentation"
                    aria-hidden="true"
                    onLoad={last ? scrollToPLP() : _ => _}
                  />
                )}
              </span>
            </StrapiLink>
            {fullWidth && (
              <AlternateImages
                image={product.primary_image}
                alternate_images={product.alternate_images}
                currentImage={currentImage}
                setImage={setImage}
              />
            )}
            <div className="product-details-section">
              <div className="product-details-data grid-x">
                {fullWidth && (
                  <div className="instock-instore cell small-12">
                    <ProductLocation />
                  </div>
                )}
                <div className="product-variation-title cell small-12 ">
                  <div className="product-variation-image-list cell small-8">
                    <div
                      className={classNames('product-variations', {
                        [classes.hidden]: !orderedProductVariations?.size && !variationType,
                      })}
                    >
                      {orderedProductVariations?.size ? (
                        <div
                          className={classNames('product-variation-info', {
                            hidden: product.category === 'mattress bedding',
                          })}
                        >
                          More Sizes Available
                        </div>
                      ) : (
                        <div
                          className={classNames('product-variation-info', {
                            hidden:
                              product.upgrade_items && product.category === 'livingroom'
                                ? !product.upgrade_items.some(item => item.title.toLowerCase().includes('sleeper')) ||
                                  (product.sub_category &&
                                    product.sub_category.some(category => category.includes('Sleeper Sofas'))) ||
                                  product.title.toLowerCase().includes('sleeper')
                                : true,
                          })}
                        >
                          Sleeper Option Available
                        </div>
                      )}
                      {variationType ? (
                        <div className="product-variation-swatches">
                          <div className={`${variationType}-variation`}>
                            <Variations
                              handleVariationClick={handleVariationClick}
                              variations={orderedProductVariations[variationType]}
                              productSku={product.sku}
                              noImage={variationType === 'size'}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="product-variation-swatches hidden">
                          <div className={classes.variantPlaceholder} />
                        </div>
                      )}
                    </div>
                  </div>
                  <StrapiLink
                    data={{
                      InternalUrl: route,
                      id: `product-tile-link:${product.sku}`,
                      Title: product.title,
                    }}
                    trackingData={{
                      category: 'product tile',
                      action: 'click',
                      label: product.sku,
                      event: 'ee_click',
                      ecommerce: {
                        click: {
                          position: index,
                          products: [analyticsProduct(product, 1, index)],
                          list: sliderTitle ? `${sliderTitle}` : `${source && source.replace(/[-_]/g, ' ')}`,
                        },
                      },
                    }}
                    aria-describedby={`sale${product.sku} price${product.sku}`}
                  >
                    <span className="product-info cell small-12 ">
                      <span className="product-title link" dangerouslySetInnerHTML={{ __html: product.title }} />
                    </span>
                  </StrapiLink>
                </div>
                <div
                  className={classNames('product-pricing small-12', {
                    'large-4': fullWidth,
                  })}
                >
                  {price && (
                    <div className="product-price cell grid-y small-12">
                      <StrapiLink
                        data={{
                          InternalUrl: route,
                          id: `price:product-tile-link:${product.sku}`,
                          Title: product.title,
                        }}
                        trackingData={{
                          category: 'product tile',
                          action: 'click',
                          label: product.sku,
                          event: 'ee_click',
                          ecommerce: {
                            click: {
                              position: index,
                              products: [analyticsProduct(product, 1, index)],
                              list: sliderTitle ? `${sliderTitle}` : `${source && source.replace(/[-_]/g, ' ')}`,
                            },
                          },
                        }}
                        tabIndex="-1"
                        aria-hidden="true"
                      >
                        <div className={classNames({ 'small-6': showFinance, 'small-12': !showFinance })}>
                          <ProductPrice
                            price={price}
                            sale={sale}
                            strikethrough={strikeThrough}
                            strikethroughPrice={strikeThroughPrice}
                          />
                          {showFinance && (
                            <div className="small-6">
                              <ProductFinancing financeAmount={financeAmount} />
                            </div>
                          )}
                        </div>
                      </StrapiLink>
                    </div>
                  )}
                </div>
                <div className="buy-button cell small-12">
                  {/* handle TRUE free shipping text in the "you recently viewed" & "you may also like" sections/sliders */}
                  {product && product.free_shipping && source !== 'search' && (
                    <p className="free-shipping slider-free-shipping">Free Shipping</p>
                  )}
                  {/* handle FALSE/empty free shipping text in the "you recently viewed" & "you may also like" sections/sliders */}
                  {product && !product.free_shipping && source !== 'search' && (
                    <p className="free-shipping slider-no-free-shipping">&nbsp;&nbsp;</p>
                  )}
                  {shouldDisplayAddToCart && (
                    <AddToCart
                      pageName={PLP}
                      availability={availability}
                      product={product}
                      price={price}
                      componentPage="product tile"
                      focused={focused}
                      removeFocus={removeFocus}
                      source={source}
                      addons={addons || product.addon_items}
                      activeAddons={[]}
                      index={index}
                      moreInfoButton={isMobile}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

ProductInfoGrid.propTypes = {
  product: object,
  strikeThrough: bool,
  strikeThroughPrice: number,
  availability: bool,
  fullWidth: bool,
  currentImage: string,
  setImage: func,
  index: number,
  sliderTitle: string,
  source: string,
  isMobile: bool,
  addons: arrayOf(any),
  displayQuantity: number,
  showFinance: bool,
  financeAmount: number,
  last: bool,
  setProduct: func,
  orderedProductVariations: object,
}

export default ProductInfoGrid
