// dependecies
import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useInView } from 'react-intersection-observer'
// helpers
import { withoutFlickering } from '@helpers/strapi'

const getProductURL = sku => `${process.env.GATSBY_AWS_PRODUCT_URL}${sku.toUpperCase()}.json`

const fetchProductBySku = ({ queryKey }) => {
  const [_key, sku] = queryKey

  if (!sku || typeof sku !== 'string') {
    return Promise.resolve(null)
  }

  return fetch(getProductURL(sku)).then(response =>
    !response.ok
      ? Promise.reject(new Error(`Error fetching product data: ${response.status} | ${response.statusText}`))
      : response.json().then(({ variations, ...product }) => ({ variations, product })),
  )
}

/**
 * Fetches, caches and memonizes product data of a given SKU, while pre-fetching variations
 * @param {string} sku product SKU - default null
 * @param {boolean} antiFlickering prevents UI flashes when data loads too fast - default true
 */
const useProductQuery = (sku = null, antiFlickering = true, showSwatches = true) => {
  const queryClient = useQueryClient()
  const [inViewRef, inView] = useInView({ threshold: 0.3, skip: !sku, triggerOnce: true })
  const [isLoaded, setIsLoaded] = useState(false)
  const [variations, setVariations] = useState({})

  const result = useQuery({
    queryKey: ['products', sku],
    enabled: Boolean(sku) && inView,
    queryFn: withoutFlickering(fetchProductBySku, 1200, antiFlickering && !isLoaded),
  })

  const { data, isLoading, ...otherResultData } = result

  /* In order to maintain variation order, we store product variations after the first load only  */
  useEffect(() => {
    if (!isLoading && data && !isLoaded) {
      setIsLoaded(true)
      if (showSwatches) {
        setVariations(data.variations)
      }
    }
  }, [isLoading, data, isLoaded, showSwatches])

  /* Pre-fecth product variations when the product-tile is at least 30% in viewport */
  useEffect(() => {
    let variationType = null

    if (!inView) return

    if (variations?.color && variations?.color?.length > 0) {
      variationType = 'color'
    } else if (variations?.finish && variations?.finish?.length > 0) {
      variationType = 'finish'
    }

    if (variationType && variations[variationType]) {
      variations[variationType].forEach(variation => {
        queryClient.prefetchQuery(['products', variation?.sku], fetchProductBySku)
      })
    }
  }, [variations, queryClient, inView])

  return {
    ...otherResultData,
    isLoaded,
    inViewRef,
    isLoading,
    product: data?.product || null,
    variations,
  }
}

export default useProductQuery
