import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { debounce } from 'lodash'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { NoSsr } from '@material-ui/core'
import { breakPoints } from '@constants/styles'
import { ProductPrice, Quantity, theme, SystemProvider } from '@rtgdev/design-system'
import {
  getStockMessage,
  getSleepTrialModalByBrand,
  renderLTLmessage,
  enhanceStockMessage,
  productPrice,
  productOnSale,
  renderSeeInStore,
} from '@helpers/product'
import { currencyFormatUS } from '@helpers/string-helper'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { getFinancePlans, productFinancing } from '@helpers/finance'
import { isInViewport } from '@helpers/viewport'
import { productUrl } from '@helpers/route'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { isMobile } from 'react-device-detect'
import PageLoader from '@components/shared/page-loader'
import { AddonModelPropType } from '@models/api/products/addon-model'
import LoadProductVariations from '../product-parts/product-variations-refactor'
import Options from '../product-parts/product-options'
import ProductAddToCartSection from '../product-parts/product-add-to-cart-section'
import ProductSleepTrialLink from '../product-parts/product-sleep-trial-link'
import SeeInStores from '../product-parts/product-see-in-stores-wrapper'
import 'react-image-lightbox/style.css'
import Addons from './Addons'
import Branding from './Branding'

const TitleDiv = styled.h1`
  font-size: 1.5rem;
  line-height: 1.625rem;
  color: #333;
  text-align: left;
  padding: 0;
`
const TitleDivMobile = styled.h1`
  font-size: 1rem;
  color: #333;
  text-align: left;
  font-weight: 700;
  padding: 0 0 5px 0;
`
const SkuDiv = styled.div`
  font-size: 0.75rem;
  color: #333;
  padding: 0 0 0 1px;
`
const PriceWrapper = styled(Grid)`
  margin: 0 0 20px 0 !important;
`
const StockWrapperDesktop = styled(Grid)`
  margin: 20px 0 23px 0;
`
const StockWrapperMobile = styled(Grid)`
  display: block;
  margin: 10px 0 20px 0;
`
const VariationWrapperDesktop = styled(Grid)`
  padding: 0 0 15px 0;
`
const VariationWrapperMobile = styled(Grid)`
  padding: 15px 0;
`
const FreeShippingStyled = styled('div')`
  color: ${theme?.palette?.tertiary?.main};
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
`
const StockMessageStyled = styled('span')`
  font-size: 1rem;
`
const SpanStyled = styled('span')`
  height: 1.25rem;
  display: grid;
`
const AssemblyRequiredStyled = styled('div')`
  font-size: 1rem;
  font-weight: 700;
`
const LTLmessageStyled = styled('span')`
  font-size: 1rem;
`
const QuantityWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const BoxDesktopMargin = styled(Box)`
  @media only screen and (min-width: ${breakPoints.medium}) {
    margin-right: 10px;
  }
`
const QuantityContainer = styled.div`
  @media only screen and (max-width: ${breakPoints.small}) {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  > div.MuiGrid-root {
    width: auto;
    flex-wrap: nowrap;
  }
`
const HrNoTopMargin = styled.hr`
  margin-top: 0;
`
const HrNoBottomMargin = styled.hr`
  margin-bottom: 0;
`
const RoomIncludes = styled(Box)`
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1rem;
  display: flex;
  align-items: start;
  @media only screen and (min-width: ${breakPoints.medium}) {
    font-size: 100%;
  }
`
const RoomIncludesWrapper = styled(Box)`
  display: flex;
  margin: 0;
  padding-bottom: 10px;
  @media only screen and (min-width: ${breakPoints.medium}) {
    padding: 15px 0;
  }
  @media only screen and (min-width: ${breakPoints.small}) {
    padding: 15px 0;
  }
`
const RoomIncludesBox = styled(Box)`
  margin-right: 10px;
  display: flex;
  align-items: start;
  font-size: 1rem;
`

const ITEM_TYPES = {
  ROOM: 'room',
}

const ITEM_BRANDS = {
  BEDS_TO_GO: 'beds to go',
  CASPER: 'casper',
}

const SCROLL_POSITION = 860
const DEBOUNCE_STOCK_MESSAGE_TIME = 250
const PREQUALIFIED_LINK =
  'https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=72D604A6DC14650CBC0382E5D3916FEFAC9588579FD11A00&preQual=Y&sitecode='

const ProductInfo = ({
  product,
  strikeThrough,
  strikeThroughPrice,
  free_shipping,
  userSelectedAddons,
  setUserSelectedAddons,
  region,
  requiredAddonsSelected,
  items_in_room,
  room_savings,
  sliderTile,
}) => {
  const [stockMessage, setStockMessage] = useState('')
  const setStockMessageDebounced = debounce(setStockMessage, DEBOUNCE_STOCK_MESSAGE_TIME)
  const [showStockMessage, setShowStockMessage] = useState(false)
  const [productAvailability, setProductAvailability] = useState(false)
  const [loading, setLoading] = useState(false)

  const [shouldHideAddToCartWrapper, setShouldHideAddToCartWrapper] = useState(false)
  const [warrantyEnabled, setWarrantyEnabled] = useState(false)
  const [displayLTLmessage, setDisplayLTLmessage] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [scrolled, setScrolled] = useState(false)
  const price = productPrice(product, false)
  const sale = productOnSale(product)
  const { financeAmount, showFinance } = productFinancing(price, product.delivery_type)

  const renderSeeInStoreButton = renderSeeInStore({
    region,
    delivery_type: product.delivery_type,
  })

  useEffect(() => {
    updateScrollPosition()

    window.addEventListener('scroll', toggleAddProductToCartVisibility, false)
    window.addEventListener('scroll', updateScrollPosition)

    return () => {
      window.removeEventListener('scroll', toggleAddProductToCartVisibility)
      window.removeEventListener('scroll', updateScrollPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        setShowStockMessage(true)

        if (product) {
          await getStockMessage(product, setStockMessageDebounced, setProductAvailability)
        }

        const result = await renderLTLmessage({
          delivery_sub_type_code: product?.delivery_sub_type_code,
          vendorId: product?.vendorId,
        })
        setDisplayLTLmessage(result)
      } finally {
        setLoading(false)
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const updateScrollPosition = () => {
    if (window.scrollY > SCROLL_POSITION && !scrolled) {
      setScrolled(true)
    } else if (window.scrollY <= SCROLL_POSITION && scrolled) {
      setScrolled(false)
    }
  }

  /* If an "add offer to cart" button is in the viewport, hide the "add product to cart" button */
  const toggleAddProductToCartVisibility = () => {
    if (isMobile) {
      const offerButtons = document.querySelectorAll('.offerButton')

      if (offerButtons) {
        const hasOfferInView = Array.from(offerButtons).some(isInViewport)

        if (hasOfferInView && !shouldHideAddToCartWrapper) {
          setShouldHideAddToCartWrapper(true)
        }
        if (!hasOfferInView && shouldHideAddToCartWrapper) {
          setShouldHideAddToCartWrapper(false)
        }
      }
    }
  }

  const handleOptionClick = (label, trackingData) => {
    //  Append tracking data to window for analytics purposes
    addToDataLayer(trackingData)

    //  Get url and route
    const url = productUrl(product.upgrade_items.filter(i => i.label === label)[0].route)
    navigate(url)
  }

  const addons = product?.addon_items || []
  const availableAddons = addons.filter(addon => !!addon.catalog_availability[region])
  const optionalAddons = availableAddons?.filter(addon => !addon.addon_required)
  const SleepTrialModal = product ? getSleepTrialModalByBrand(product) : null
  const highestPriorityFinancePlan = getFinancePlans(null, null, true)?.[0]
  const monthly = highestPriorityFinancePlan?.numberOfMonths || null
  const financeAmountText = `$${financeAmount}`

  /* create the customer assembly required message */
  const assemblyRequired = product.customer_assembly_required
  const assemblyRequiredNote =
    assemblyRequired && (Object.is(product.delivery_type, 'O') || Object.is(product.delivery_type, 'U'))
      ? 'Assembly required'
      : false

  /* if the user is out of market or there are no addons or warranty isn't available in any region  */
  const noAddons =
    Object.is(region, 'OOM') ||
    Object.is(optionalAddons?.length, 0 || Object.is(product.warranty_pricing?.length, 0) || !getStateEligiblity()) ||
    Object.is(productAvailability, false)

  const LTLmessageContent = () => (
    <>
      <Typography variant="subtitle">
        The carrier will contact you directly to schedule your delivery, which includes placing the box into the first
        room of your home. <strong>Please note that freight deliveries can take 7-21 days in transit.</strong>
      </Typography>
    </>
  )

  if (!product || loading || !stockMessage) return <PageLoader />

  return (
    <SystemProvider>
      <Grid item xs={12}>
        <Hidden implementation="css" only="xs">
          {product.brand && (
            <Grid item xs={12}>
              <Branding brand={product.brand} />
            </Grid>
          )}
          {/* DESKTOP */}
          <Grid item xs={12}>
            <TitleDiv dangerouslySetInnerHTML={{ __html: product?.displayNamePDP || product.title }} />
            <SkuDiv>{`SKU: ${product.sku}`}</SkuDiv>
          </Grid>
        </Hidden>
        <Hidden implementation="css" mdUp>
          {product.brand && (
            <Grid container item xs={12} justify="center" style={{ width: '35vw' }}>
              <Branding brand={product.brand} />
            </Grid>
          )}
          <Grid item xs={12}>
            <TitleDivMobile dangerouslySetInnerHTML={{ __html: product?.displayNamePDP || product.title }} />
          </Grid>
        </Hidden>
        {/* ITEMS IN ROOM - DESKTOP */}
        <Hidden implementation="css" only="xs">
          {items_in_room && (
            <RoomIncludesWrapper>
              <RoomIncludesBox>Includes:</RoomIncludesBox>
              <RoomIncludes>
                {items_in_room
                  .map(({ quantity: item_quantity, generic_name }) =>
                    item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                  )
                  .join(' • ')}
              </RoomIncludes>
            </RoomIncludesWrapper>
          )}
        </Hidden>
        {/* PRODUCT VARIANTS */}
        <div />
        {product.variations && (
          <>
            <Hidden implementation="css" smDown>
              <VariationWrapperDesktop item xs={12}>
                {product.variations.color ? (
                  <LoadProductVariations
                    variations={product.variations.color}
                    heading="Color"
                    productSku={product.sku}
                  />
                ) : null}
                {product.variations.finish ? (
                  <LoadProductVariations
                    variations={product.variations.finish}
                    heading="Finish"
                    productSku={product.sku}
                  />
                ) : null}
                {product.variations.size ? (
                  <LoadProductVariations variations={product.variations.size} heading="Size" productSku={product.sku} />
                ) : null}
              </VariationWrapperDesktop>
            </Hidden>
            <Hidden implementation="css" mdUp>
              <VariationWrapperMobile item xs={12}>
                {product.variations.color ? (
                  <LoadProductVariations
                    variations={product.variations.color}
                    heading="Color"
                    productSku={product.sku}
                  />
                ) : null}
                {product.variations.finish ? (
                  <LoadProductVariations
                    variations={product.variations.finish}
                    heading="Finish"
                    productSku={product.sku}
                  />
                ) : null}
                {product.variations.size ? (
                  <LoadProductVariations variations={product.variations.size} heading="Size" productSku={product.sku} />
                ) : null}
              </VariationWrapperMobile>
            </Hidden>
          </>
        )}
        {/* ITEMS IN ROOM - MOBILE */}
        <Hidden implementation="css" mdUp>
          {items_in_room && (
            <RoomIncludesWrapper>
              <RoomIncludesBox>Includes:</RoomIncludesBox>
              <RoomIncludes>
                {items_in_room
                  .map(({ quantity: item_quantity, generic_name }) =>
                    item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                  )
                  .join(' • ')}
              </RoomIncludes>
            </RoomIncludesWrapper>
          )}
        </Hidden>
        {/* PRODUCT UPGRADE OPTIONS */}
        {product.upgrade_items && (
          <Grid item xs={12}>
            <Options
              options={product.upgrade_items}
              productSku={product.sku}
              handleChange={handleOptionClick}
              filterAvailability={product.filterAvailability}
            />
          </Grid>
        )}
        {/* PRODUCT (BESIDES ROOMS) QUANTITY PICKER */}
        {product.type && product.type !== ITEM_TYPES.ROOM && (
          <Grid item xs={12}>
            <HrNoBottomMargin />
            <QuantityWrapper>
              <BoxDesktopMargin>Quantity:</BoxDesktopMargin>
              <QuantityContainer>
                <Quantity initialQuantity={quantity} onDecrement={setQuantity} onIncrement={setQuantity} />
              </QuantityContainer>
            </QuantityWrapper>
            <Hidden implementation="css" mdUp>
              <HrNoTopMargin />
            </Hidden>
          </Grid>
        )}
        {/* PRODUCT PRICE -- Desktop */}
        <Hidden implementation="css" smDown>
          <PriceWrapper item>
            <HrNoTopMargin />
            <ProductPrice
              price={currencyFormatUS(price)}
              strikeThroughPrice={currencyFormatUS(strikeThroughPrice)}
              sale={sale}
              strikeThrough={strikeThrough}
              availability={productAvailability}
              freeShipping={free_shipping}
              financeAmount={financeAmountText}
              monthly={monthly}
              showFinancing={showFinance}
              room_savings={room_savings}
              prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d1`}
            />
          </PriceWrapper>
        </Hidden>
        {/* STOCK MESSAGE -- DESKTOP */}
        <Hidden implementation="css" smDown>
          <StockWrapperDesktop>
            {!showStockMessage && (
              <StockMessageStyled id="stock-message">
                <SpanStyled> </SpanStyled>
                <LinearProgress />
              </StockMessageStyled>
            )}
            {stockMessage && showStockMessage && (
              <>
                <StockMessageStyled
                  id="stock-message"
                  dangerouslySetInnerHTML={{ __html: enhanceStockMessage(stockMessage) }}
                />
                {/* LTL Message */}
                {displayLTLmessage && (
                  <LTLmessageStyled>
                    <LTLmessageContent />
                  </LTLmessageStyled>
                )}
                {assemblyRequiredNote && <AssemblyRequiredStyled>{assemblyRequiredNote}</AssemblyRequiredStyled>}
              </>
            )}
          </StockWrapperDesktop>
        </Hidden>
        {/* STOCK MESSAGE -- Mobile */}
        <Hidden implementation="css" mdUp>
          <StockWrapperMobile>
            {!showStockMessage && (
              <>
                <SpanStyled> </SpanStyled>
                <LinearProgress />
              </>
            )}
            {stockMessage && showStockMessage && (
              <>
                {product?.free_shipping && <FreeShippingStyled>Free Shipping</FreeShippingStyled>}
                <StockMessageStyled id="stock-message" dangerouslySetInnerHTML={{ __html: stockMessage }} />
                {/* LTL Message */}
                {displayLTLmessage && (
                  <LTLmessageStyled>
                    <LTLmessageContent />
                  </LTLmessageStyled>
                )}
                {assemblyRequiredNote && <AssemblyRequiredStyled>{assemblyRequiredNote}</AssemblyRequiredStyled>}
              </>
            )}
          </StockWrapperMobile>
        </Hidden>
        {/* SEE IN STORE -- Mobile */}
        {renderSeeInStoreButton && (
          <Hidden implementation="css" mdUp>
            <SeeInStores
              componentPage="pdp"
              primary_image={product.primary_image}
              sku={product.sku}
              title={product.title}
            />
          </Hidden>
        )}
        {/* ADD TO CART / SEE IN STORE -- Tablet */}
        <NoSsr>
          <Hidden implementation="css" lgUp>
            <Hidden only="xs">
              <Box justify="space-evenly" alignItems="stretch" direction="column" container>
                <Box width="100%">
                  <ProductAddToCartSection
                    quantity={quantity}
                    availability={productAvailability}
                    product={product}
                    price={price}
                    componentPage="pdp"
                    stockMessage={stockMessage}
                    addons={addons}
                    requiredSelected={requiredAddonsSelected}
                    activeAddons={userSelectedAddons}
                    warrantyEnabled={warrantyEnabled}
                    showLocation={false}
                    hideShipping
                    moreInfoButton={false}
                  />
                </Box>
              </Box>
            </Hidden>
          </Hidden>
        </NoSsr>

        {/* ADD TO CART / SEE IN STORE -- Desktop */}
        <NoSsr>
          <Hidden implementation="css" mdDown>
            <ProductAddToCartSection
              quantity={quantity}
              availability={productAvailability}
              product={product}
              price={price}
              componentPage="pdp"
              stockMessage={stockMessage}
              addons={addons}
              requiredSelected={requiredAddonsSelected}
              activeAddons={userSelectedAddons}
              warrantyEnabled={warrantyEnabled}
              showLocation={false}
              hideShipping
              moreInfoButton={false}
              region={region}
            />
          </Hidden>
        </NoSsr>

        {!noAddons && (
          <Grid item>
            <Addons
              userSelectedAddons={userSelectedAddons}
              setUserSelectedAddons={setUserSelectedAddons}
              setWarrantyEnabled={setWarrantyEnabled}
              optionalAddons={optionalAddons}
              warrantyEnabled={warrantyEnabled}
              warrantyPrice={product.warrantyPrice}
              warranty_pricing={product.warranty_pricing}
            />
          </Grid>
        )}
        {product.brand &&
          (product.brand.toLowerCase() === ITEM_BRANDS.BEDS_TO_GO ||
            product.brand.toLowerCase() === ITEM_BRANDS.CASPER) && (
            <ProductSleepTrialLink SleepTrialModal={SleepTrialModal} />
          )}
      </Grid>
      {/* STICKY DRAWER FOR MOBILE BRANDING/TITLE/PRICE/ADD TO CART */}
      <NoSsr>
        <Hidden implementation="css" smUp>
          <Box
            position="fixed"
            bottom={0}
            left={0}
            padding="10px"
            bgcolor="white"
            width="100vw"
            zIndex={2}
            borderTop="1px solid #dedede"
          >
            <ProductPrice
              price={currencyFormatUS(price)}
              strikeThroughPrice={currencyFormatUS(strikeThroughPrice)}
              sale={sale}
              strikeThrough={strikeThrough}
              availability={productAvailability}
              showFinancing={showFinance}
              financeAmount={financeAmountText}
              monthly={monthly}
              prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d2`}
            />
            <ProductAddToCartSection
              quantity={quantity}
              availability={productAvailability}
              product={product}
              price={price}
              componentPage="pdp"
              stockMessage={stockMessage}
              addons={addons}
              requiredSelected={requiredAddonsSelected}
              activeAddons={userSelectedAddons}
              warrantyEnabled={warrantyEnabled}
              showLocation={false}
              hideShipping
              moreInfoButton={false}
              region={region}
            />
          </Box>
        </Hidden>
      </NoSsr>
    </SystemProvider>
  )
}

ProductInfo.propTypes = {
  product: PropTypes.object,
  sliderTile: PropTypes.string,
  items_in_room: PropTypes.object,
  strikeThrough: PropTypes.bool,
  strikeThroughPrice: PropTypes.number,
  free_shipping: PropTypes.bool,
  region: PropTypes.string,
  userSelectedAddons: PropTypes.arrayOf(AddonModelPropType),
  setUserSelectedAddons: PropTypes.func,
  requiredAddonsSelected: PropTypes.arrayOf(AddonModelPropType),
  room_savings: PropTypes.number,
}

export default ProductInfo
