import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { object, number, string, bool, func, objectOf, any, arrayOf } from 'prop-types'
import { productOnSale, isProductStrikeThrough, productPrice, productAvailability } from '@helpers/product'
import { productFinancing } from '@helpers/finance'
import { connect } from 'react-redux'
import { AddonModelPropType } from '@models/api/products/addon-model'
import ProductTile from './views/product-info-grid'
import ProductDetail from './views/product-info'
import '../plpIncludes/product/views/product-info-grid-includes'

const useStyles = makeStyles({
  wrapper: {
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
})

function getProductInfo(product, region) {
  const price = productPrice(product)
  const { financeAmount, showFinance } = productFinancing(price, product.delivery_type)
  const addons = product?.addon_items
  const strikeThrough = isProductStrikeThrough(product)
  const strikeThroughPrice = productPrice(product, true)
  const availability = productAvailability(product)
  const sale = productOnSale(product)
  const variationCount = product?.variation_types ? Object.keys(product.variation_types).length : 0

  return {
    financeAmount: financeAmount ?? 0,
    showFinance,
    addons,
    price,
    strikeThrough,
    strikeThroughPrice,
    availability,
    sale,
    variationCount,
  }
}

function ProductInfo(props) {
  const {
    data: product,
    fullWidth,
    viewType = 'grid',
    index,
    isMobile,
    source,
    displayQuantity,
    promotions,
    orderedProductVariations,
    setProduct,
    last,
    showIncludes,
    productType,
    region,
    sliderTitle,
    setUserSelectedAddons,
    userSelectedAddons,
    requiredAddonsSelected,
    items_in_room,
    room_savings,
    inViewRef,
    id,
  } = props

  const [currentImage, setCurrentImage] = useState(null)
  const {
    showFinance,
    financeAmount,
    price,
    strikeThrough,
    availability,
    variationCount,
    addons,
    strikeThroughPrice,
    sale,
  } = useCallback(getProductInfo(product, region))

  const commonProps = {
    product,
    fullWidth,
    price,
    strikeThrough,
    strikeThroughPrice,
    availability,
    currentImage,
    setImage: setCurrentImage,
    variationCount,
    isMobile,
    index,
    source,
    addons,
    showFinance,
    financeAmount,
    showIncludes,
    productType,
    sale,
  }

  const classes = useStyles()

  const view = {
    grid: (
      <ProductTile
        {...commonProps}
        displayQuantity={displayQuantity}
        setProduct={setProduct}
        orderedProductVariations={orderedProductVariations}
        last={last}
        sku={product.sku}
        sliderTitle={sliderTitle}
      />
    ),
    list: (
      <ProductDetail
        {...commonProps}
        free_shipping={product?.free_shipping}
        promotions={promotions}
        region={region}
        setUserSelectedAddons={setUserSelectedAddons}
        userSelectedAddons={userSelectedAddons}
        requiredAddonsSelected={requiredAddonsSelected}
        items_in_room={items_in_room}
        room_savings={room_savings}
      />
    ),
  }[viewType]
  return (
    <div className={classes.wrapper} data-testid={id} ref={inViewRef}>
      {view}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.global,
  region: state.location.rtg_location && state.location.rtg_location.region,
})

ProductInfo.propTypes = {
  data: object,
  fullWidth: bool,
  viewType: string,
  index: number,
  isMobile: bool,
  source: string,
  sliderTitle: string,
  displayQuantity: number,
  promotions: objectOf(any),
  orderedProductVariations: object,
  setProduct: func,
  last: bool,
  showIncludes: bool,
  productType: string,
  inViewRef: any.isRequired,
  id: string.isRequired,
  region: string,
  setUserSelectedAddons: func,
  userSelectedAddons: arrayOf(AddonModelPropType),
  requiredAddonsSelected: arrayOf(AddonModelPropType),
  room_savings: number,
  items_in_room: objectOf(any),
}

export default connect(mapStateToProps)(ProductInfo)
