import React from 'react'
import { any, shape, string, func, number, instanceOf, oneOfType } from 'prop-types'
import SaleFlagComponent from '@components/SaleFlag'
import { getProductSaleFlagType, SaleFlagType, getCustomSaleFlag } from '@helpers/product'

/* determine whether to show a red flag or not, then figure out what text to display */
const SaleFlag = ({ product, availabilityDate, sale, onClick, bonusBuyOverrideText }) => {
  let flagText = getProductSaleFlagType({ product, availabilityDate, sale })
  let flagTextColor = '#fff'
  let flagBgColor = '#E31C3D'

  let cursor = 'default'
  if (flagText === SaleFlagType.BonusBuy) {
    flagText = bonusBuyOverrideText
    cursor = 'pointer'
  }

  const customSaleFlag = getCustomSaleFlag(product)
  if (customSaleFlag) {
    flagText = customSaleFlag.text
    if (customSaleFlag.textColor) flagTextColor = customSaleFlag.textColor
    if (customSaleFlag.bgColor) flagBgColor = customSaleFlag.bgColor
  }

  if (flagText) {
    return (
      <span onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0" style={{ cursor }}>
        <SaleFlagComponent backgroundColor={flagBgColor} color={flagTextColor} label={flagText} />
      </span>
    )
  }
  return null
}

SaleFlag.propTypes = {
  product: shape({
    closeout: any,
    inStock: string,
    qualifier_value: number,
  }),
  sale: any,
  onClick: func,
  bonusBuyOverrideText: string,
  availabilityDate: oneOfType([string, instanceOf(Date)]),
}

SaleFlag.defaultProps = {
  product: {},
  availabilityDate: undefined,
  sale: '',
  onClick: () => {},
  bonusBuyOverrideText: 'BONUS BUY!',
}

export default SaleFlag
