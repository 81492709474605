import React from 'react'
import Box from '@mui/material/Box'
import { string } from 'prop-types'
import {
  RTGBaby,
  Beautyrest,
  BeautyrestBlack,
  BeautyrestSilver,
  BedsToGo,
  Bedgear,
  Casper,
  CindyCrawfordHome,
  Disney,
  DisneyPrincess,
  DisneyStarWars,
  EricChurch,
  IComfortSerta,
  Kids,
  Kingsdown,
  RTGModern,
  Molecule,
  Nectar,
  Patio,
  Purple,
  Restonic,
  RTGKids,
  RTGPatio,
  StearnsAndFoster,
  Sealy,
  Serta,
  SofiaVergaraCollection,
  Sunbrella,
  Simmons,
  Teens,
  Tempurpedic,
  Therapedic,
} from '../../../assets/images/brands'

// The total size of importing all 28 of these logos is 455 KB
// PR 2546 sets up the loadable package for dynamic imports
// When that PR gets merged, update this component to dynamically import these images

const getBrandImage = brand => {
  switch (brand) {
    case 'baby': // no branding
      return RTGBaby
    case 'beautyrest':
      return Beautyrest
    case 'beautyrest black':
      return BeautyrestBlack
    case 'beautyrest silver':
      return BeautyrestSilver
    case 'beds to go':
      return BedsToGo
    case 'bedgear':
      return Bedgear
    case 'casper':
      return Casper
    case 'cindy crawford':
      return CindyCrawfordHome
    case 'disney':
      return Disney
    case 'disney princess':
      return DisneyPrincess
    case 'disney star wars':
      return DisneyStarWars
    case 'eric church highway to home':
      return EricChurch
    case 'serta icomfort':
      return IComfortSerta
    case 'kids':
      return Kids
    case 'kingsdown':
      return Kingsdown
    case 'modern':
      return RTGModern
    case 'molecule':
      return Molecule
    case 'nectar':
      return Nectar
    case 'patio':
      return Patio
    case 'purple':
      return Purple
    case 'restonic':
      return Restonic
    case 'rtg kids':
      return RTGKids
    case 'rtg patio':
      return RTGPatio
    case 'stearns and foster':
      return StearnsAndFoster
    case 'sealy':
      return Sealy
    case 'serta':
      return Serta
    case 'serta perfect sleeper':
      return Serta
    case 'sofia vergara':
      return SofiaVergaraCollection
    case 'fifth & shore': // this maps to sunbrella?
      return Sunbrella
    case 'sunbrella':
      return Sunbrella
    case 'simmons':
      return Simmons
    case 'teens':
      return Teens
    case 'tempur-pedic':
      return Tempurpedic
    case 'therapedic':
      return Therapedic
    default:
      return ''
  }
}

const Branding = ({ brand }) => {
  if (!brand) return null
  const brandImg = getBrandImage(brand)
  if (!brandImg) return null
  return (
    <Box
      alignItems="center"
      justify="flex-start"
      sx={{
        m: 0,
        '& img': {
          maxHeight: 25,
        },
      }}
    >
      <img alt={`${brand} logo`} role="presentation" aria-hidden="true" src={brandImg} />
    </Box>
  )
}

Branding.propTypes = {
  brand: string,
}

export default Branding
