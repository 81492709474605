import React from 'react'
import { AddonModelPropType } from '@models/api/products/addon-model'
import { array, bool, func, object, string, arrayOf } from 'prop-types'
import styled from 'styled-components'
import { productUrl } from '@helpers/route'
import RTGLink from '@shared/link'
import { productPrice } from '@helpers/product'
import { currencyFormatUS, titleCase } from '@helpers/string-helper'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import _some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import ProductWarrantyLink from '../product-parts/product-warranty-link'

export const Addon = styled.li`
  align-items: center;
  color: #333;
  display: flex;
  font-size: 16px;
  margin-bottom: 15px;
  @media only screen and (max-width: 639px) {
    font-size: 14px;
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    font-size: 16px;
    color: #0053a0;
    text-decoration: underline;

    @media only screen and (max-width: 639px) {
      font-size: 14px;
    }

    svg {
      margin-right: 5px;
    }
  }

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-left: 1.25rem;
  }

  span {
    color: #333;
    font-size: 14px;
    margin-right: 5px;
    line-height: 1.7;
    @media only screen and (max-width: 639px) {
      font-size: 14px;
    }
  }

  input[type='checkbox'] {
    margin: 0 0 0 0.188rem;
    min-width: 0.8125rem;
    min-height: 0.8125rem;
    background-color: #ffffff;
    transform: scale(1.5);
    vertical-align: sub;
    border: 0.0625rem solid #333;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:checked {
      background: #0053a0;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0.25rem;
      top: 0.0625rem;
      width: 0.25rem;
      height: 0.5rem;
      border: solid #ffffff;
      border-width: 0 0.125rem 0.125rem 0;
      transform: rotate(45deg);
    }
    &:hover {
      cursor: pointer;
      border: 0.0625rem solid #0053a0;
    }
  }
`

const Heading = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 0;
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
`

const Addons = ({
  optionalAddons,
  setUserSelectedAddons,
  setWarrantyEnabled,
  userSelectedAddons,
  warrantyEnabled,
  warrantyPrice,
  warranty_pricing,
}) => {
  const handleAddonClick = addon => {
    if (_some(userSelectedAddons, { sku: addon.sku })) {
      setUserSelectedAddons(userSelectedAddons.filter(selectedAddons => selectedAddons.sku !== addon.sku))
    } else {
      setUserSelectedAddons([...userSelectedAddons, addon])
    }
  }

  const warranty = !isEmpty(warranty_pricing) && getStateEligiblity() && (
    <>
      <Heading>Protect Your Purchase:</Heading>
      <ProductWarrantyLink
        setWarrantyEnabled={setWarrantyEnabled}
        warrantyEnabled={warrantyEnabled}
        warrantyPrice={warrantyPrice}
        warranty_pricing={warranty_pricing}
      />
    </>
  )

  const addons = Array.isArray(optionalAddons) && optionalAddons.length > 0 && (
    <>
      <Heading>Add-ons:</Heading>
      <ul>
        {optionalAddons.map((addon, index) => (
          <Addon key={addon.sku}>
            <input
              data-testid="addonCheckbox"
              checked={_some(userSelectedAddons, { sku: addon.sku })}
              id={`addon_${addon.sku}`}
              type="checkbox"
              value={addon.sku}
              onChange={() => handleAddonClick(addon)}
            />
            <label htmlFor={`addon_${addon.sku}`}>
              <span style={{ lineHeight: '16px' }}>
                {`${titleCase(addon?.label || '', [' ', '('])} - ${currencyFormatUS(
                  productPrice(addon) * addon.quantity,
                )} ${addon.quantity > 1 ? ` (${addon.quantity})` : ''}`}
                {addon.sell_individually && (
                  <RTGLink
                    data-testid="see-details-link"
                    data={{
                      slug: productUrl(addon.route || null),
                    }}
                    trackingData={{
                      event: 'ee_click',
                      ecommerce: {
                        click: {
                          list: `addon_`,
                          position: index + 1,
                          products: [addon?.analyticsProduct],
                        },
                      },
                    }}
                  >
                    <span style={{ fontSize: 14, color: '#0053a0' }}>See Details</span>
                  </RTGLink>
                )}
              </span>
              <span className="hide508">View product page for:</span>
            </label>
          </Addon>
        ))}
      </ul>
    </>
  )

  return (
    <>
      {warranty}
      {addons}
    </>
  )
}

Addons.propTypes = {
  optionalAddons: array,
  setWarrantyEnabled: func,
  setUserSelectedAddons: func,
  userSelectedAddons: arrayOf(AddonModelPropType),
  warrantyEnabled: bool,
  warrantyPrice: string,
  warranty_pricing: object,
}

Addons.defaultProps = {
  optionalAddons: [],
  warranty_pricing: {},
}

export default Addons
