// dependencies
import { useState, useMemo, useEffect } from 'react'
// helpers
import { getCurrentLocation, getRegionZone } from '@helpers/geo-location'
import { productPrice, productOnSale, displayAddToCartButton } from '@helpers/product'
import { productUrl } from '@helpers/route'

/**
 * @typedef {{category: string, breadcrumb: object, type: string, room_configurations: object, delivery_type: string, availabilityByDistIndex: array, availabilityDate: string}} Args
 * @param {Args} product
 * @returns {void}
 */
// eslint-disable-next-line import/prefer-default-export
export function useProduct(product) {
  const [availabilityDate, setAvailabilityDate] = useState('')
  const rtg_location = getCurrentLocation()
  const { region } = getRegionZone()

  const price = productPrice(product)
  const sale = productOnSale(product)
  const route = productUrl(product?.route)
  const isMattress = product?.category?.match('bedding') && product?.breadcrumb?.breadcrumb_label?.match('Mattress')
  const isItem = product?.type?.match('item') && !product?.room_configurations

  const shouldDisplayAddToCart = useMemo(
    () => displayAddToCartButton({ delivery_type: product?.delivery_type, region }),
    [product, region],
  )

  useEffect(() => {
    if (product?.availabilityByDistIndex) {
      const regionAvailability = product?.availabilityByDistIndex[rtg_location?.distribution_index]
      const isAvailableInCurrentRegion = regionAvailability?.inStock.includes('now') || regionAvailability?.isAvailable
      const availDate = isAvailableInCurrentRegion ? regionAvailability?.availabilityDate : ''
      setAvailabilityDate(availDate)
    } else {
      setAvailabilityDate(product.availabilityDate)
    }
  }, [
    product.sku,
    rtg_location.distribution_index,
    rtg_location.state,
    product.availabilityByDistIndex,
    product.availabilityDate,
  ])

  return { price, sale, route, isMattress, isItem, availabilityDate, shouldDisplayAddToCart }
}
