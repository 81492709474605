import React from 'react'
import { number, bool } from 'prop-types'
import { getFinancePlans } from '@helpers/finance'
import '../../../assets/css/components/product/product-parts/pricing.sass'

const ProductFinancing = ({ financeAmount, availability = true }) => {
  const highestPriorityFinancePlan = getFinancePlans(null, null, true)[0]
  const monthly = highestPriorityFinancePlan?.numberOfMonths || null

  return (
    <>
      {availability && (
        <>
          {monthly ? (
            <>
              <div className="product-financing">{`$${financeAmount}/mo. with ${monthly} mos. financing*`}</div>
            </>
          ) : (
            <div className="product-financing">{`$${financeAmount} A MONTH`}</div>
          )}
        </>
      )}
    </>
  )
}

ProductFinancing.propTypes = {
  financeAmount: number,
  availability: bool,
}
export default ProductFinancing
