/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Modal from 'react-modal'
import Cookies from 'universal-cookie'
import { validateZip, letters, weekdays } from '@helpers/string-helper'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import { number, objectOf, any, string, bool, shape, func } from 'prop-types'
import { getSeeInStore } from '@services/product'
import '../../../assets/css/components/product/product-parts/see-in-stores-modal.sass'
import StoreInfo from '../../store-locator/store-info'
import StoreSearchBar from '../../store-locator/search-bar'
import MapLocationsList from '../../store-locator/map-locations-list'

const cookies = new Cookies()

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

export class SeeInStoresModal extends React.Component {
  state = {
    markerconfigs: [],
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    zipInput: '',
    markerObjects: [],
    zip: cookies.get('__Host-rtg_zip') || '33584',
    currentDay: '',
    tomorrowDay: '',
    invalidZip: false,
    submittedZip: '',
    available: true,
    doneLoading: false,
  }

  componentDidMount() {
    this.setMapMarkers()
    const d = new Date()
    const t = new Date()
    t.setDate(d.getDate() + 1)
    this.setState({
      currentDay: weekdays[d.getDay()],
      tomorrowDay: weekdays[t.getDay()],
    })
  }

  getInitialCenter() {
    const { storeLat, storeLng } = this.props
    const { markerconfigs } = this.state
    let lat
    let lng
    if (storeLat && storeLng) {
      lat = storeLat
      lng = storeLng
    } else if (markerconfigs.length) {
      lat = markerconfigs[0].lat
      lng = markerconfigs[0].lng
    } else if (cookies.get('__Host-rtg_location') !== undefined) {
      const rtg_location = cookies.get('__Host-rtg_location')
      lat = rtg_location.lat
      lng = rtg_location.long
    } else {
      lat = 28.004051
      lng = -82.308449
    }

    return {
      lat,
      lng,
    }
  }

  setMapMarkers() {
    const { zip: zipState } = this.state
    const { zip: zipProps, sku } = this.props
    const zip = zipState || zipProps || cookies.get('__Host-rtg_zip') || cookies.get('__Host-rtg_location')?.zip
    if (sku && typeof sku !== 'undefined' && zip !== '' && typeof zip !== 'undefined') {
      const markers = []
      getSeeInStore(sku, zip)
        .then(data => {
          if (data && data.length) {
            data.forEach(addr => {
              const config = {
                title: addr.address1,
                name: addr.storeName,
                lat: addr.latitude,
                lng: addr.longitude,
                city: addr.city,
                state: addr.state,
                storeNumber: addr.storeNumber,
                zip: addr.zipcode,
                phoneNumber: addr.phoneNumber,
                hours: addr.hours,
                ratings: addr.ratings,
              }
              markers.push(config)
            })
            this.setState({
              markerconfigs: markers,
              doneLoading: true,
            })
          } else {
            this.setState({
              markerconfigs: [],
              doneLoading: true,
            })
          }
        })
        .catch(() =>
          this.setState({
            available: false,
            doneLoading: true,
          }),
        )
    }
  }

  onMarkerMounted = element => {
    if (element && element.marker) {
      this.setState(prevState => ({
        markerObjects: [...prevState.markerObjects, () => element.marker],
      }))
    }
  }

  onLocationClick = i => {
    const { markerconfigs, markerObjects } = this.state
    this.setState({
      selectedPlace: markerconfigs[i],
      activeMarker: markerObjects[i](),
      showingInfoWindow: true,
    })
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })
  }

  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  initMap = () => {
    const { google } = this.props
    const { markerconfigs, activeMarker, showingInfoWindow, selectedPlace } = this.state
    return (
      <Map
        google={google}
        initialCenter={this.getInitialCenter()}
        center={this.getInitialCenter()}
        zoom={8}
        onClick={this.onMapClicked}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
      >
        {markerconfigs.map((config, i) => (
          <Marker
            ref={this.onMarkerMounted}
            title={config.title}
            id={`marker-${i}`}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            name={config.name}
            phoneNumber={config.phoneNumber}
            city={config.city}
            state={config.state}
            storeNumber={config.storeNumber}
            zip={config.zip}
            position={{ lat: config.lat, lng: config.lng }}
            hours={config.hours}
            onClick={this.onMarkerClick}
            icon={{
              url: `https://maps.google.com/mapfiles/marker${letters[i]}.png`,
            }}
          />
        ))}
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <StoreInfo selectedMarker={selectedPlace} />
        </InfoWindow>
      </Map>
    )
  }

  getLocations() {
    const { lia } = this.props
    const { markerconfigs, submittedZip, available, currentDay, tomorrowDay, doneLoading } = this.state
    if (!markerconfigs || !markerconfigs.length || !available)
      return (
        <div>
          {(doneLoading && submittedZip && !available) ||
            (doneLoading && available && markerconfigs.length === 0 && (
              <p className="message">
                We apologize, but this product is not available at any locations near {submittedZip}.
              </p>
            ))}
          {doneLoading && !submittedZip && !available && (
            <p className="message">We apologize, but this product is not on display in your region</p>
          )}
          {!doneLoading && <p className="message">Loading ...</p>}
        </div>
      )
    return (
      <MapLocationsList
        markers={markerconfigs}
        onLocationClick={this.onLocationClick}
        currentDay={currentDay}
        tomorrowDay={tomorrowDay}
        lia={lia}
      />
    )
  }

  updateInputZipValue = evt => {
    this.setState({
      zipInput: evt.target.value,
      zip: evt.target.value,
      invalidZip: false,
    })
  }

  changeZip = () => {
    const { zipInput } = this.state
    if (validateZip(zipInput)) {
      this.setMapMarkers()
      this.initMap()
      this.render()
    } else {
      this.setState({
        invalidZip: true,
      })
    }
    this.setState({
      submittedZip: zipInput,
      available: true,
    })
  }

  render() {
    const { modalOpen, closeModal, primary_image, sku, title } = this.props
    const { invalidZip, zipInput, markerconfigs, available } = this.state
    if (!sku || typeof sku === 'undefined') return null
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="See In Stores Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content see-in-store-modal">
          <div className="card grid-x">
            <div className="leftScroll">
              <h1 className="title">{title || ''}</h1>
              <img src={`${primary_image}&w=250`} alt={title} />
              <StoreSearchBar
                invalidZip={invalidZip}
                zipInput={zipInput}
                updateInputZipValue={this.updateInputZipValue}
                changeZip={this.changeZip}
              />
              <h2 className="heading">Available To See At The Following Showrooms</h2>
              {modalOpen && this.getLocations()}
            </div>
            {markerconfigs && markerconfigs.length > 0 && available && (
              <div className="map">
                <div className="mapHolder" id="mapHolder">
                  {modalOpen && this.initMap()}
                </div>
              </div>
            )}
          </div>
          <button
            className="close-modal"
            tabIndex="0"
            value="Close"
            aria-label="Close"
            onClick={closeModal}
            type="button"
          >
            <img
              className="icon close"
              alt="close icon"
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
            />
          </button>
        </div>
      </Modal>
    )
  }
}

SeeInStoresModal.propTypes = {
  closeModal: func,
  lia: bool,
  google: shape({
    maps: objectOf(any),
  }),
  modalOpen: bool,
  primary_image: string,
  sku: string,
  storeLat: number,
  storeLng: number,
  title: string,
  zip: string,
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
})(SeeInStoresModal)
